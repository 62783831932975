import React, { useState } from "react";
import {
  FaPhone,
  FaRegFileAlt,
  FaHospital,
  FaGavel,
  FaVideo,
  FaLanguage,
  FaBriefcase,
  FaGraduationCap,
  FaLandmark,
  FaComment,
} from "react-icons/fa";
import { FiArrowRight } from "react-icons/fi";
import Flag from "react-world-flags";
// images
import LandingImg from "../../src/assets/images/adobe/landing-img.webp";
import VriImg from "../../src/assets/images/adobe/vri-img.webp";


const interpretationTypes = [
  {
    name: "On-Site Interpretation",
    icon: FaRegFileAlt,
    description:
      "Ideal for face-to-face meetings, conferences, legal hearings, and events.",
    image: "/path/to/on-site.jpg",
    link: "/on-site",
  },
  {
    name: "Over-the-Phone Interpretation (OPI)",
    icon: FaPhone,
    description: "On-demand interpretation for immediate language support.",
    image: "/path/to/over-the-phone.jpg",
    link: "/over-the-phone",
  },
  {
    name: "Video Remote Interpretation (VRI)",
    icon: FaVideo,
    description:
      "Real-time solutions for virtual meetings, webinars, and telehealth.",
    image: VriImg,
    link: "/video-remote-interpretation",
  },
  {
    name: "Sign Language Interpretation",
    icon: FaHospital,
    description:
      "Inclusive services for the deaf and hard-of-hearing communities.",
    image: "/path/to/sign-language.jpg",
    link: "/sign-language",
  },
  {
    name: "Consecutive Interpretation",
    icon: FaComment,
    description:
      "Perfect for events, negotiations, and one-on-one interactions.",
    image: "/path/to/consecutive-simultaneous.jpg",
    link: "/consecutive-simultaneous",
  },
  {
    name: "Simultaneous Interpretation",
    icon: FaLanguage,
    description:
      "Perfect for events, negotiations, and one-on-one interactions.",
    image: "/path/to/consecutive-simultaneous.jpg",
    link: "/consecutive-simultaneous",
  },
];

const industries = [
  {
    name: "Legal",
    icon: FaGavel,
    description: "Courtroom proceedings, depositions, and consultations.",
    image: "/path/to/legal.jpg",
    link: "/legal",
  },
  {
    name: "Healthcare",
    icon: FaHospital,
    description:
      "Medical appointments, patient care, and hospital communication.",
    image: "/path/to/medical.jpg",
    link: "/medical",
  },
  {
    name: "Immigration",
    icon: FaPhone,
    description: "Support for immigration-related interpretations.",
    image: "/path/to/immigration.jpg",
    link: "/immigration",
  },
  {
    name: "Corporate",
    icon: FaBriefcase,
    description:
      "Board meetings, international conferences, and training sessions.",
    image: "/path/to/corporate.jpg",
    link: "/corporate",
  },
  {
    name: "Education",
    icon: FaGraduationCap,
    description:
      "Academic events, parent-teacher meetings, and classroom instruction.",
    image: "/path/to/education.jpg",
    link: "/education",
  },
  {
    name: "Government and Public Sector",
    icon: FaLandmark,
    description: "Policy discussions, public hearings, and community outreach.",
    image: "/path/to/government.jpg",
    link: "/government",
  },
];

const languages = [
  {
    name: "English",
    code: "US",
    link: "/language-expertise/english-expertise",
  },
  {
    name: "Spanish",
    code: "ES",
    link: "/language-expertise/spanish-expertise",
  },
  { name: "French", code: "FR", link: "/language-expertise/french-expertise" },
  { name: "German", code: "DE", link: "/language-expertise/german-expertise" },
  {
    name: "Italian",
    code: "IT",
    link: "/language-expertise/italian-expertise",
  },
  {
    name: "Portuguese",
    code: "PT",
    link: "/language-expertise/portuguese-expertise",
  },
  {
    name: "Japanese",
    code: "JP",
    link: "/language-expertise/japanese-expertise",
  },
  {
    name: "Chinese",
    code: "CN",
    link: "/language-expertise/chinese-expertise",
  },
  { name: "Arabic", code: "SA", link: "/language-expertise/arabic-expertise" },
  {
    name: "Haitian",
    code: "HT",
    link: "/language-expertise/haitian-expertise",
  },
];

const defaultItem = {
  name: "Trusted Experts in Interpretation Services",
  description:
    "With over 20 years of experience and a network of more than 10,000 certified interpreters, Trusted Interpreters is your global partner for professional interpretation services. We specialize in bridging language barriers with precision, professionalism, and cultural sensitivity, offering services in over 200 languages. Whether you need on-site, over-the-phone, or video remote interpretation, our team delivers exceptional solutions tailored to your specific needs.",
  image: LandingImg,
  link: "/services",
};

const LandingInfo: React.FC = () => {
  const [selectedItem, setSelectedItem] = useState<{
    name: string;
    description: string;
    image: string;
    link: string;
  } | null>(null);

  return (
    <div className="container mx-auto px-4 py-4 space-y-8">
      {/* Selected Content */}
      <div className="bg-white rounded-lg shadow-md p-6 flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-6">
        <div className="w-full md:w-1/2 flex justify-center">
          <img
            src={selectedItem?.image || defaultItem.image}
            alt={selectedItem?.name || defaultItem.name}
            className="rounded-lg shadow-md object-cover h-full w-full md:w-auto"
          />
        </div>
        <div className="w-full md:w-1/2 flex flex-col justify-center text-center md:text-left">
          <h3 className="text-lg md:text-xl font-bold text-gray-800 mb-2">
            {selectedItem?.name || defaultItem.name}
          </h3>
          <p className="text-sm md:text-base text-gray-600 mb-4">
            {selectedItem?.description || defaultItem.description}
          </p>
          <a
            href={selectedItem?.link || defaultItem.link}
            className="text-blue-600 hover:text-blue-800 font-medium flex items-center justify-center md:justify-start"
          >
            See more <FiArrowRight className="ml-2" />
          </a>
        </div>
      </div>

      {/* Unified Row with Divider */}
      <div className="flex flex-col space-y-8">
        <div className="flex items-start justify-center space-x-8">
          {/* Interpretation Types */}
          <div className="flex flex-col items-center space-y-4 flex-1">
            <h3 className="text-lg font-semibold text-gray-800">
              Our Interpretation Types
            </h3>
            <div className="flex justify-around flex-wrap w-full max-w-3xl gap-6">
              {interpretationTypes.map((type, index) => (
                <button
                  key={index}
                  onClick={() => setSelectedItem(type)}
                  className="w-1/4 flex flex-col items-center space-y-1 text-gray-700 hover:text-blue-600 text-center group"
                >
                  <type.icon className="text-blue-600 text-xl mb-1 transition-transform duration-200 group-hover:scale-125" />
                  <span className="text-sm font-semibold">{type.name}</span>
                </button>
              ))}
            </div>
          </div>

          {/* Divider */}
          <div className="border-l border-gray-300 self-stretch"></div>

          {/* Industries Expertise */}
          <div className="flex flex-col items-center space-y-4 flex-1">
            <h3 className="text-lg font-semibold text-gray-800">
              Our Industries Expertise
            </h3>
            <div className="flex justify-around flex-wrap w-full max-w-3xl gap-12">
              {industries.map((industry, index) => (
                <button
                  key={index}
                  onClick={() => setSelectedItem(industry)}
                  className="w-1/4 flex flex-col items-center space-y-1 text-gray-700 hover:text-blue-600 text-center group"
                >
                  {/* Icon with hover effect */}
                  <industry.icon className="text-blue-600 text-xl mb-1 transition-transform duration-200 group-hover:scale-125" />
                  <span className="text-sm font-semibold">{industry.name}</span>
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Divider for Languages */}
        <div className="border-t border-gray-300 w-full"></div>

        {/* Languages Section */}
        <div className="flex flex-col items-center space-y-4">
          <h3 className="text-lg font-semibold text-gray-800">
            Our Languages Expertise
          </h3>
          <div className="flex flex-wrap justify-center space-x-16">
            {languages.map((language, index) => (
              <a
                key={index}
                href={language.link}
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col items-center text-gray-700 hover:text-blue-600 text-center"
              >
                <Flag
                  code={language.code}
                  style={{
                    width: "50px",
                    height: "35px",
                    borderRadius: "4px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                />
                <span className="text-sm font-semibold">{language.name}</span>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingInfo;
