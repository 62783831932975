import React from "react";
import PageHeader from "../../components/PageHeader";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import TestimonialsSection from "../../components/TestimonialsSection";
import Footer from "../../components/Footer";
// icons
import { FaGlobe, FaBalanceScale, FaUserFriends } from "react-icons/fa";
// images
import SpanishImage from "../../../src/assets/images/adobe/spanish-img.webp";
import PeopleImage from "../../../src/assets/images/adobe/people-img.webp";


const SpanishExpertise: React.FC = () => {
  const benefits = [
    {
      icon: FaGlobe,
      title: "Broad Dialect Coverage",
      text: "From Latin America to Spain, we cover all dialects of Spanish.",
    },
    {
      icon: FaBalanceScale,
      title: "Industry Expertise",
      text: "Specialized interpreters for legal, medical, and business contexts.",
    },
    {
      icon: FaUserFriends,
      title: "Cultural Accuracy",
      text: "Ensuring culturally appropriate and precise translations.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      {/* Encabezado */}
      <PageHeader
        title="Spanish Language Expertise"
        description="Connecting you to expert Spanish linguists for seamless communication in a wide range of industries."
        imageUrl={SpanishImage}
      />

      {/* Industries Served */}
      <ContentSection
        title="Industries We Serve"
        content="Our Spanish language expertise spans multiple industries, ensuring precise and culturally aware communication in business, education, healthcare, and more."
        imageUrl={PeopleImage}
        direction="right"
      />

      {/* Key Benefits */}
      <HighlightedSection title="Why Choose Our Spanish Experts?">
        <KeyBenefits benefits={benefits} />
      </HighlightedSection>

      {/* How It Works */}
      <HighlightedSection title="How Does It Work?">
        <ProcessWorkflow process="presencial" />
      </HighlightedSection>

      {/* Testimonials */}
      <TestimonialsSection />

      {/* Interlinking */}
      <div className="container mx-auto px-6">
        <LanguageLinks currentLanguage="Spanish" />
      </div>

      <Footer />
    </div>
  );
};

export default SpanishExpertise;
