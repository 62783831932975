import React from "react";

interface PricingPlan {
  icon: React.ElementType;
  title: string;
  price: number;
  description: string;
  benefits: string[];
}

interface PricingPlansProps {
  title: string;
  plans: PricingPlan[];
}

const PricingPlans: React.FC<PricingPlansProps> = ({ title, plans }) => {
  return (
    <div className="container mx-auto px-4 py-2">
      {/* title */}
      <h2 className="text-3xl font-bold text-center text-gray-800 mb-4">
        {title}
      </h2>
      <p className="text-lg text-center text-gray-600 mb-8">
        Choose the service that suits your needs.
      </p>

      {/* grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {plans.map((plan, index) => (
          <div
            key={index}
            className="group relative flex flex-col mx-auto w-full max-w-sm bg-white rounded-2xl shadow-2xl transition-all duration-300 p-8 xl:p-12 hover:shadow-xl"
          >
            {/* icon */}
            <div className="border-b border-solid border-gray-200 pb-9 mb-9">
              <div className="w-16 h-16 rounded-full bg-indigo-50 mx-auto flex justify-center items-center transition-all duration-300 group-hover:bg-indigo-600">
                <plan.icon className="w-6 h-6 text-indigo-600 transition-all duration-300 group-hover:text-white" />
              </div>
              {/* title */}
              <h3 className="font-manrope text-2xl font-bold my-7 text-center text-indigo-600 group-hover:text-indigo-800">
                {plan.title}
              </h3>
              {/* price */}
              <div className="flex items-center justify-center text-4xl font-medium text-gray-900">
                <span>From ${plan.price}</span>
                <span className="text-xl text-gray-500 ml-3">/hour</span>
              </div>
            </div>
            {/* benefits list */}
            <ul className="mb-12 space-y-6 text-left text-lg text-gray-600 group-hover:text-gray-900">
              {plan.benefits.map((benefit, idx) => (
                <li key={idx} className="flex items-center space-x-3.5">
                  <span className="w-1.5 h-1.5 rounded-full bg-indigo-600"></span>
                  <span>{benefit}</span>
                </li>
              ))}
            </ul>
            {/* get quote */}
            <a
              href="#"
              className="py-2.5 px-5 bg-indigo-50 shadow-sm rounded-full transition-all duration-500 text-base text-indigo-600 font-semibold text-center w-fit mx-auto group-hover:bg-indigo-600 group-hover:text-white"
            >
              Get a Quote
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingPlans;
