import React from 'react';
import { FaGlobe, FaCheckCircle, FaClock } from 'react-icons/fa';

const ProcessBenefits: React.FC = () => {
  return (
    <div className="relative flex items-center justify-center h-96 w-full md:w-2/3 ml-8">
      <div className="absolute bg-orange-600 h-48 w-48 rounded-full opacity-30 z-0"></div>

      <img 
        src="https://www.cerquetech.com/assets/images/shapes/shape_circle_dashed_1.svg" 
        alt="dashed circle" 
        className="absolute h-80 w-80 animate-spin-slow z-0" 
        style={{ animationDuration: '30s' }}
      />

      <div className="relative flex flex-col items-center z-10">
        <div className="flex space-x-20 mb-4">
          <BenefitCircle icon={<FaGlobe />} color="bg-orange-500" text="Global Experts" />
          <BenefitCircle icon={<FaCheckCircle />} color="bg-pink-500" text="Guaranteed Quality" />
        </div>
        <div className="mt-20">
          <BenefitCircle icon={<FaClock />} color="bg-teal-500" text="Quick Response" />
        </div>
      </div>
    </div>
  );
};

const BenefitCircle: React.FC<{ icon: React.ReactNode; color: string; text: string }> = ({ icon, color, text }) => {
  return (
    <div className="flex flex-col items-center bg-gray-100 h-32 w-32 rounded-full shadow-lg p-4 text-center">
      <div className={`h-12 w-12 rounded-full flex items-center justify-center ${color} text-white mb-2`}>
        {icon}
      </div>
      <p className="text-gray-800 font-semibold text-sm">{text}</p>
    </div>
  );
};

export default ProcessBenefits;
