import React from 'react';

const SignLanguage: React.FC = () => {
  return (
    <div>
      <h1>Sign Language Interpretation</h1>
    </div>
  );
};

export default SignLanguage;
