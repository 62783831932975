import React from "react";
import Footer from "../../components/Footer";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import AboutUsExtras from "../../components/AboutUsExtras";
import AboutUsImage from '../../assets/images/about-us-img.webp';

const AboutUs: React.FC = () => {
  return (
    <div className="min-h-screen">
      <div className="container mx-auto px-4 py-16">
        <div className="sm:flex items-center max-w-screen-xl mx-auto">
          <div className="md:w-1/2 flex justify-center">
            <img
              src={AboutUsImage}
              alt="About Us"
              className="w-full max-w-sm md:max-w-md animate-slide-in"
            />
          </div>
          <div className="sm:w-1/2 p-5">
            <div className="text animate-slide-in">
              <span className="text-gray-500 border-b-2 border-blue-600 uppercase">
                About us
              </span>
              <h2 className="my-4 font-bold text-3xl sm:text-4xl">
                About <span className="text-blue-600">Our Company</span>
              </h2>
              <p className="text-gray-700 mb-6">
                Trusted Interpreters was founded with a mission to bridge
                communication gaps across languages and cultures. We provide
                high-quality interpretation services tailored to the unique
                needs of businesses and individuals. With a team of certified
                professionals, we ensure seamless communication in legal,
                medical, and corporate settings.
              </p>
              <p className="text-gray-700 mb-6">
                Our commitment to excellence is evident in every project we
                undertake. By leveraging the latest technology and our
                extensive industry expertise, we aim to deliver outstanding
                solutions that foster understanding and connection between
                diverse communities.
              </p>
              <button className="bg-blue-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition">
                Request a Quote Now
              </button>
            </div>
          </div>
        </div>

        <AboutUsExtras />

        <InteractiveMap />

        <FAQ />
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
