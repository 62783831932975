import React from "react";
import LandingInfo from "../../components/LandingInfo";
import ClientsCarousel from "../../components/ClientsCarousel";
import OurProcess from "../../components/OurProcess";
import InitialSection from "../../components/InitialSection";
import FAQ from "../../components/FAQ";
import TestimonialsSection from "../../components/TestimonialsSection";
import Footer from "../../components/Footer";
import PricingPlans from "../../components/PricingPlans";
import HighlightedSection from "../../components/HighlightedSection";
import KeyBenefits from "../../components/KeyBenefits";
import ContentSection from "../../components/ContentSection";
import CitiesGrid from "../../components/CitiesGrid";
import CallToAction from "../../components/CallToAction";
import {
  FaCertificate,
  FaGlobe,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaTools,
  FaUserShield,
  FaVideo,
  FaWrench,
} from "react-icons/fa";
// images
import OnsiteImage2 from "../../../src/assets/images/adobe/onsite-img-2.webp";

const Home: React.FC = () => {
  const pricingPlans = [
    {
      icon: FaMapMarkerAlt,
      title: "On-Site",
      price: 50,
      description: "Ideal for individuals and small teams",
      benefits: [
        "Access to essential services",
        "Email support",
        "Monthly billing",
      ],
    },
    {
      icon: FaPhoneAlt,
      title: "Over-The-Phone",
      price: 100,
      description: "Perfect for growing businesses",
      benefits: [
        "Priority support",
        "Access to premium features",
        "Quarterly billing",
      ],
    },
    {
      icon: FaVideo,
      title: "VRI",
      price: 200,
      description: "For large organizations and enterprises",
      benefits: [
        "Dedicated account manager",
        "Custom integrations",
        "Annual billing",
      ],
    },
  ];

  const benefits = [
    {
      icon: FaCertificate,
      title: "Certified Professionals",
      text: "Our interpreters are trained to ensure accuracy and cultural competence.",
    },
    {
      icon: FaGlobe,
      title: "Global Availability",
      text: "Services delivered locally and internationally, anytime and anywhere.",
    },
    {
      icon: FaTools,
      title: "Advanced Tools",
      text: "Cutting-edge technology for seamless interpretation, including proprietary scheduling systems.",
    },
    {
      icon: FaUserShield,
      title: "Confidentiality and Ethics",
      text: "Adherence to strict privacy and ethical standards.",
    },
    {
      icon: FaWrench,
      title: "Customized Solutions",
      text: "Flexible options tailored to fit your unique project needs.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <div className="container mx-auto px-4 py-16">
        <InitialSection />

        <ClientsCarousel />

        <LandingInfo />

        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={benefits} />
        </HighlightedSection>

        <OurProcess />

        <PricingPlans
          title="Interpretation Services starting from $50"
          plans={pricingPlans}
        />

        <ContentSection
          title="Any Language, Any Place, Any Time"
          content="At Trusted Interpreters, we are dedicated to making communication accessible across borders. From urgent requests to long-term projects, we are here to provide reliable, high-quality interpretation services that suit your requirements.
                  With 24/7 availability and a global network of professionals, we ensure you’ll never face language barriers again."
          imageUrl={OnsiteImage2}
          direction="left"
        />

        <CitiesGrid />

        <CallToAction />

        <FAQ />

        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
