import React from 'react';

const RID: React.FC = () => {
  return (
    <div>
      <h1>RID-Certified ASL Interpretation Service</h1>
    </div>
  );
};

export default RID;