import React from 'react';

const Simultaneous: React.FC = () => {
  return (
    <div>
      <h1>Simultaneous Interpretation</h1>
      <p>Simultaneous interpretation allows for real-time translation, ideal for conferences and large events.</p>
    </div>
  );
};

export default Simultaneous;
