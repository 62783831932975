import React from 'react';

const ASL: React.FC = () => {
  return (
    <div>
      <h1>American Sign Language Interpretation Service</h1>
    </div>
  );
};

export default ASL;