import React from 'react';

const Equipment: React.FC = () => {
  return (
    <div>
      <h1>Interpretation Equipment</h1>
      <p>We provide the necessary equipment for simultaneous interpretation, including headsets and microphones.</p>
    </div>
  );
};

export default Equipment;
