import React from "react";
import { Link } from "react-router-dom";
import { ClipboardList, Mic, Headphones } from "lucide-react";

const OnSiteMenu: React.FC<{ onMouseLeave: () => void }> = ({ onMouseLeave }) => {
  return (
    <div
      className="absolute bg-white shadow-lg rounded-lg p-4 mt-2 w-64 z-50"
      onMouseLeave={onMouseLeave}
    >
      <ul>
        <li className="hover:bg-gray-100 rounded">
          <Link to="/interpretation-types/on-site/consecutive" className="flex items-center p-2">
            <ClipboardList className="h-6 w-6 text-blue-600 mr-3" />
            <div>
              <p className="text-gray-800 font-semibold">Consecutive</p>
              <p className="text-gray-500 text-sm">Interpretation for pauses between sentences.</p>
            </div>
          </Link>
        </li>
        <li className="hover:bg-gray-100 rounded">
          <Link to="/interpretation-types/on-site/simultaneous" className="flex items-center p-2">
            <Mic className="h-6 w-6 text-blue-600 mr-3" />
            <div>
              <p className="text-gray-800 font-semibold">Simultaneous</p>
              <p className="text-gray-500 text-sm">Real-time interpretation for events.</p>
            </div>
          </Link>
        </li>
        <li className="hover:bg-gray-100 rounded">
          <Link to="/interpretation-types/on-site/equipment" className="flex items-center p-2">
            <Headphones className="h-6 w-6 text-blue-600 mr-3" />
            <div>
              <p className="text-gray-800 font-semibold">Equipment</p>
              <p className="text-gray-500 text-sm">Equipment rental for interpretation.</p>
            </div>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default OnSiteMenu;
