import React from 'react';

const ESL: React.FC = () => {
  return (
    <div>
      <h1>English Sign Language Interpretation Service</h1>
    </div>
  );
};

export default ESL;