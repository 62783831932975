import React from "react";

interface ContentSectionProps {
  title: string;
  content?: string;
  contentList?: string[];
  additionalContent?: string;
  imageUrl?: string;
  direction?: "left" | "right";
}

const ContentSection: React.FC<ContentSectionProps> = ({
  title,
  content,
  contentList,
  additionalContent,
  imageUrl,
  direction = "left",
}) => {
  const isTextLeft = direction === "left";

  return (
    <div className="container mx-auto px-6 py-12 mt-6">
      <div
        className={`flex flex-col md:flex-row items-center ${
          isTextLeft ? "md:flex-row" : "md:flex-row-reverse"
        } bg-white shadow-lg rounded-lg overflow-hidden`}
      >
        {/* Texto */}
        <div className="md:w-1/2 p-6">
          <h2 className="text-3xl font-bold text-gray-800 mb-4">{title}</h2>
          {content && <p className="text-lg text-gray-600 leading-relaxed mb-4">{content}</p>}
          {contentList && (
            <ul className="text-lg text-gray-600 leading-relaxed list-disc list-inside mb-4">
              {contentList.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          )}
          {additionalContent && (
            <p className="text-lg text-gray-600 leading-relaxed">{additionalContent}</p>
          )}
        </div>

        {/* Imagen */}
        {imageUrl && (
          <div className="md:w-1/2">
            <img
              src={imageUrl}
              alt={title}
              className="object-cover w-full h-full"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ContentSection;
