import React from "react";
import Flag from "react-world-flags";

interface LanguageLinksProps {
  currentLanguage: string;
}

const LanguageLinks: React.FC<LanguageLinksProps> = ({ currentLanguage }) => {
  const languages = [
    {
      name: "English",
      code: "US",
      link: "/language-expertise/english-expertise",
    },
    {
      name: "Spanish",
      code: "ES",
      link: "/language-expertise/spanish-expertise",
    },
    {
      name: "French",
      code: "FR",
      link: "/language-expertise/french-expertise",
    },
    {
      name: "German",
      code: "DE",
      link: "/language-expertise/german-expertise",
    },
    {
      name: "Italian",
      code: "IT",
      link: "/language-expertise/italian-expertise",
    },
    {
      name: "Portuguese",
      code: "PT",
      link: "/language-expertise/portuguese-expertise",
    },
    {
      name: "Japanese",
      code: "JP",
      link: "/language-expertise/japanese-expertise",
    },
    {
      name: "Chinese",
      code: "CN",
      link: "/language-expertise/chinese-expertise",
    },
    {
      name: "Arabic",
      code: "SA",
      link: "/language-expertise/arabic-expertise",
    },
    {
      name: "Haitian",
      code: "HT",
      link: "/language-expertise/haitian-expertise",
    },
  ];

  const filteredLanguages = languages.filter(
    (language) => language.name.toLowerCase() !== currentLanguage.toLowerCase()
  );

  return (
    <div className="flex flex-col items-center space-y-8 mb-20">
      <h3 className="text-4xl font-semibold text-gray-800">
        Explore More Languages
      </h3>
      <div className="flex flex-wrap justify-center gap-16">
        {filteredLanguages.map((language) => (
          <a
            key={language.name}
            href={language.link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col items-center text-gray-700 hover:text-blue-600 text-center"
          >
            <Flag
              code={language.code}
              style={{
                width: "50px",
                height: "35px",
                borderRadius: "4px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            />
            <span className="text-sm font-semibold">{language.name}</span>
          </a>
        ))}
      </div>
    </div>
  );
};

export default LanguageLinks;
