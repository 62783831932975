
import React from "react";

const Washington: React.FC = () => {
  return (
    <div>
      <h1>Interpretation Services in washington</h1>
      <p>
        Welcome to our page for interpretation services in washington. 
        We provide top-notch interpretation services for various needs.
      </p>
    </div>
  );
};

export default Washington;
