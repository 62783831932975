import React from 'react';

const CallToAction: React.FC = () => {
  return (
    <section className="py-16">
      <div className="px-4 sm:px-6 lg:px-8">
        <div
          className="p-10 lg:py-14 lg:pl-20 lg:pr-10 rounded-2xl text-white flex items-center justify-between flex-col lg:flex-row"
          style={{ background: 'linear-gradient(to right, #024772, #e5e9ec)' }}
        >
          <div className="lg:w-3/4 text-center lg:text-left">
            <h2 className="font-manrope text-4xl font-semibold mb-5 lg:mb-2">
                Start Your Interpretation Journey Today
            </h2>
            <p className="text-base">
                Break down communication barriers with Trusted Interpreters. Whether you need on-site services for an upcoming event, video interpretation for virtual meetings, or industry-specific expertise, we are here to help.
                Contact us today to explore our services and receive a personalized quote tailored to your project.
            </p>
          </div>
          <a
            href="#contact"
            className="flex items-center gap-2 bg-white hover:bg-gray-200 rounded-full shadow-md text-lg text-blue-700 font-semibold py-4 px-8 transition-all duration-500"
          >
            Contact Us
            <svg
              width="19"
              height="14"
              viewBox="0 0 19 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.75 7L16.4167 7M11.8333 12.5L16.6852 7.64818C16.9907 7.34263 17.1435 7.18985 17.1435 7C17.1435 6.81015 16.9907 6.65737 16.6852 6.35182L11.8333 1.5"
                stroke="#024772"
                strokeWidth="2.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </a>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;

