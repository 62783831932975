import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import client from '../../services/contentfulClient';

const PostPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<any | null>(null);

  useEffect(() => {
    client
      .getEntries({
        content_type: 'blogPost',
        'fields.slug': slug,
      })
      .then((response) => {
        if (response.items.length > 0) {
          setPost(response.items[0]);
        }
      })
      .catch(console.error);
  }, [slug]);

  if (!post) {
    return <div className="container mx-auto px-4 py-16">Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-16">
      <h1 className="text-4xl font-bold mb-4">{post.fields.title}</h1>

      <div className="text-gray-800 text-lg leading-relaxed mb-6">
        {post.fields.body.content.map((block: any, index: number) => (
          <p key={index} className="mb-4">
            {block.content[0]?.value}
          </p>
        ))}
      </div>

      {post.fields.media?.fields?.file?.url && (
        <div className="flex justify-center mt-6">
          <img
            src={post.fields.media.fields.file.url}
            alt={post.fields.title}
            className="max-w-full max-h-96 rounded-lg shadow-md"
          />
        </div>
      )}
    </div>
  );
};

export default PostPage;
