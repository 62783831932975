import React, { useState, useEffect } from "react";
import {
  FaClipboardCheck,
  FaCalendarAlt,
  FaUserClock,
  FaCommentDots,
} from "react-icons/fa";
import RequestImg from "../../src/assets/images/adobe/request.png";
import CityImage from "../../src/assets/images/adobe/city-img.webp";
import InterpreterImage from "../../src/assets/images/adobe/interpreter.webp";
import GreetingImage from "../../src/assets/images/adobe/greeting-img.webp";

interface ProcessWorkflowProps {
  process: "presencial" | "virtual";
}

const ProcessWorkflow: React.FC<ProcessWorkflowProps> = ({ process }) => {
  const presencialSteps = [
    {
      icon: <FaClipboardCheck size={24} />,
      title: "Submit Your Request",
      description:
        "Provide details about your project, including location, time, and required language.",
      illustration: RequestImg,
    },
    {
      icon: <FaCalendarAlt size={24} />,
      title: "Schedule Time and Location",
      description: "We coordinate the time and place to meet your needs.",
      illustration: CityImage,
    },
    {
      icon: <FaUserClock size={24} />,
      title: "Interpreter Arrives",
      description:
        "Our professional interpreter arrives at the agreed location and provides live interpretation.",
      illustration: InterpreterImage,
    },
    {
      icon: <FaCommentDots size={24} />,
      title: "Feedback and Support",
      description:
        "Share your experience and receive ongoing support for future needs.",
      illustration: GreetingImage,
    },
  ];

  const virtualSteps = [
    {
      icon: <FaClipboardCheck size={24} />,
      title: "Submit Your Request",
      description:
        "Provide details about your project, including the time and required language.",
      illustration: RequestImg,
    },
    {
      icon: <FaCalendarAlt size={24} />,
      title: "Schedule a Virtual Session",
      description:
        "We coordinate the time and provide a secure platform for the session.",
      illustration: CityImage,
    },
    {
      icon: <FaCommentDots size={24} />,
      title: "Feedback and Support",
      description:
        "Share your experience and receive ongoing support for future needs.",
      illustration: GreetingImage,
    },
  ];

  const steps = process === "presencial" ? presencialSteps : virtualSteps;

  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentStep((prev) => (prev + 1) % steps.length);
    }, 6500);
    return () => clearInterval(interval);
  }, [steps.length]);

  return (
    <div className="container mx-auto px-4 py-4">
      <div className="flex flex-col md:flex-row md:items-start">
        <div className="w-full md:w-1/3 space-y-6">
          {steps.map((step, index) => (
            <div
              key={index}
              className={`flex items-center space-x-4 cursor-pointer transition ${
                currentStep === index ? "bg-blue-100" : "bg-white"
              } p-4 rounded-lg shadow hover:shadow-lg`}
              onClick={() => setCurrentStep(index)}
            >
              <div className="text-3xl">{step.icon}</div>
              <div>
                <h3
                  className={`text-lg font-semibold ${
                    currentStep === index ? "text-blue-600" : "text-gray-800"
                  }`}
                >
                  {step.title}
                </h3>
              </div>
            </div>
          ))}
        </div>

        {/* Right Side: Details */}
        <div className="w-full md:w-2/3 pl-8">
          <div className="rounded-lg bg-gray-50 shadow-lg p-8 transition">
            <h3 className="text-2xl font-bold mb-4">
              {steps[currentStep].title}
            </h3>
            <p className="text-gray-600">{steps[currentStep].description}</p>
            {steps[currentStep].illustration && (
              <img
                src={steps[currentStep].illustration}
                alt={steps[currentStep].title}
                className="mb-6 mt-6 rounded-lg shadow h-full w-full"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessWorkflow;
