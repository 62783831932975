import React, { useState } from "react";
import { FaSearch, FaCity, FaArrowLeft, FaFlagCheckered, FaArrowRight, FaBuilding, FaFilm, FaLandmark, FaRocket, FaSun, FaBell, FaUmbrellaBeach, FaLaptopCode, FaMusic, FaPiggyBank, FaHatCowboy, FaTree, FaAnchor, FaBook, FaGuitar, FaHorse, FaLeaf, FaMountain, FaCoffee, FaBorderAll } from "react-icons/fa";
import { FaBridge, FaMapPin } from "react-icons/fa6";


const cities = [
  { name: "New York", description: "The Big Apple, a cultural hub.", icon: <FaBuilding /> },
  { name: "Los Angeles", description: "The City of Angels, home to Hollywood.", icon: <FaFilm /> },
  { name: "Chicago", description: "The Windy City, known for its architecture.", icon: <FaLandmark /> },
  { name: "Houston", description: "The Space City, rich in history.", icon: <FaRocket /> },
  { name: "Phoenix", description: "The Valley of the Sun, a desert paradise.", icon: <FaSun /> },
  { name: "Philadelphia", description: "The City of Brotherly Love.", icon: <FaBell /> },
  { name: "San Diego", description: "Beautiful beaches and amazing weather.", icon: <FaUmbrellaBeach /> },
  { name: "Dallas", description: "A modern metropolis in north Texas.", icon: <FaCity /> },
  { name: "San Jose", description: "Heart of Silicon Valley.", icon: <FaLaptopCode /> },
  { name: "Austin", description: "The live music capital of the world.", icon: <FaMusic /> },
  { name: "Charlotte", description: "A financial hub of the south.", icon: <FaPiggyBank /> },
  { name: "Fort Worth", description: "Western heritage meets modern Texas.", icon: <FaHatCowboy /> },
  { name: "San Francisco", description: "The Golden Gate City, tech capital.", icon: <FaBridge /> },
  { name: "Columbus", description: "A vibrant city in Ohio.", icon: <FaTree /> },
  { name: "Jacksonville", description: "Coastal city with southern charm.", icon: <FaAnchor /> },
  { name: "Indianapolis", description: "Racing capital of the world.", icon: <FaFlagCheckered /> },
  { name: "Boston", description: "A city rich in history.", icon: <FaBook /> },
  { name: "Nashville", description: "The home of country music.", icon: <FaGuitar /> },
  { name: "Oklahoma City", description: "A modern city with western roots.", icon: <FaHorse /> },
  { name: "Portland", description: "Known for its eco-friendliness.", icon: <FaLeaf /> },
  { name: "Denver", description: "The Mile High City.", icon: <FaMountain /> },
  { name: "Seattle", description: "Coffee, rain, and tech innovation.", icon: <FaCoffee /> },
  { name: "Washington", description: "The nation's capital.", icon: <FaLandmark /> },
  { name: "El Paso", description: "Where Texas meets Mexico.", icon: <FaBorderAll /> },
];


const CitiesGrid: React.FC<{ serviceName?: string }> = ({ serviceName }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  const filteredCities = cities.filter((city) =>
    city.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const itemsPerPage = 5;
  const maxIndex = Math.max(filteredCities.length - itemsPerPage, 0);

  const handlePrev = () => {
    setCurrentIndex((prev) => Math.max(prev - itemsPerPage, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => Math.min(prev + itemsPerPage, maxIndex));
  };

  const visibleCities = filteredCities.slice(
    currentIndex,
    currentIndex + itemsPerPage
  );

  return (
    <div className="container mx-auto px-4 py-12">
      {/* titulo */}
      <h2 className="text-3xl font-bold text-center text-gray-800 mb-8 flex items-center justify-center">
        <FaMapPin className="text-[#EA4335] mr-2" />
        {serviceName} Interpretation Services in
      </h2>
      
      {/* descripcion */}
      {/* <p className="text-lg text-gray-600 text-center mb-8 leading-relaxed">
        Trusted Interpreters can meet any interpretation need involving any
        subject matter including legal, business, education, medical, technical,
        military, engineering, etc. With linguists located throughout the U.S.
        and abroad, we can staff any project, in virtually any location.
      </p> */}

      {/* Buscador */}
      <div className="flex justify-center mb-8">
        <div className="relative w-full max-w-md">
          <FaSearch className="absolute left-3 top-3 text-gray-500" />
          <input
            type="text"
            placeholder="Search for a city..."
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setCurrentIndex(0);
            }}
          />
        </div>
      </div>

      <div className="relative">
        {/* Flecha izquierda */}
        {currentIndex > 0 && (
          <button
            onClick={handlePrev}
            className="absolute -left-14 top-1/2 transform -translate-y-1/2 bg-[#2563eb] text-white p-3 rounded-full shadow-lg hover:bg-[#1142ad] transition z-10"
          >
            <FaArrowLeft />
          </button>
        )}

        {/* Grid de ciudades */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
          {visibleCities.map((city) => (
            <div
              key={city.name}
              className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition transform hover:-translate-y-1"
            >
              <div className="text-blue-600 text-4xl mb-4">
                {city.icon}
              </div>
              <h3 className="text-xl font-bold text-gray-800">{city.name}</h3>
              <p className="text-gray-600">{city.description}</p>
            </div>
          ))}
        </div>

        {/* Flecha derecha */}
        {currentIndex + itemsPerPage < filteredCities.length && (
          <button
            onClick={handleNext}
            className="absolute -right-14 top-1/2 transform -translate-y-1/2 bg-[#2563eb] text-white p-3 rounded-full shadow-lg hover:bg-[#1142ad] transition z-10"
          >
            <FaArrowRight />
          </button>
        )}
      </div>

      {/* Sin resultados */}
      {filteredCities.length === 0 && (
        <p className="text-center text-gray-500 mt-4">
          No cities match your search.
        </p>
      )}
    </div>
  );
};

export default CitiesGrid;
