import React from "react";
import { Link } from "react-router-dom";
import { Phone, Repeat } from "lucide-react";

const OverThePhoneMenu: React.FC<{ onMouseLeave: () => void }> = ({ onMouseLeave }) => {
  return (
    <div
      className="absolute bg-white shadow-lg rounded-lg p-4 mt-2 w-64 z-50"
      onMouseLeave={onMouseLeave}
    >
      <ul>
        <li className="hover:bg-gray-100 rounded">
          <Link to="/interpretation-types/over-the-phone/one-time-service" className="flex items-center p-2">
            <Phone className="h-6 w-6 text-blue-600 mr-3" />
            <div>
              <p className="text-gray-800 font-semibold">One Time Service</p>
              <p className="text-gray-500 text-sm">Quick one-time interpretation.</p>
            </div>
          </Link>
        </li>
        <li className="hover:bg-gray-100 rounded">
          <Link to="/interpretation-types/over-the-phone/monthly-service" className="flex items-center p-2">
            <Repeat className="h-6 w-6 text-blue-600 mr-3" />
            <div>
              <p className="text-gray-800 font-semibold">Monthly Recurrent Service</p>
              <p className="text-gray-500 text-sm">Recurring monthly service.</p>
            </div>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default OverThePhoneMenu;
