import React from 'react';
import { Link } from 'react-router-dom';
import InterpretationImage from '../assets/images/landing-image.webp';
import { CheckCircle } from 'lucide-react';

const InitialSection: React.FC = () => {
  return (
    <section className="container mx-auto flex flex-col md:flex-row items-center px-4 space-y-8 md:space-y-0 md:space-x-12">
      <div className="md:w-1/2 space-y-4">
        <h1 className="text-4xl font-extrabold leading-tight tracking-tight text-gray-900 md:text-5xl">
          Professional Interpretation Services for <span className="text-blue-600">Every Language and Industry</span>.
        </h1>
        <p className="text-lg text-gray-500">
          Our network of professional interpreters ensures accurate communication across languages. Connect with experts who specialize in legal, medical, and business fields, available anytime, anywhere.
        </p>

        <div className="flex flex-wrap gap-2 mt-4">
          {[
            { text: '24-hour delivery options available', icon: <CheckCircle className="h-5 w-5 text-blue-500" /> },
            { text: 'Certified interpreters for official use', icon: <CheckCircle className="h-5 w-5 text-green-500" /> },
            { text: 'Specialized in urgent and scheduled services', icon: <CheckCircle className="h-5 w-5 text-purple-500" /> },
            { text: 'Support available 24/7 worldwide', icon: <CheckCircle className="h-5 w-5 text-yellow-500" /> },
          ].map((benefit, index) => (
            <div key={index} className="flex items-center space-x-2 text-gray-700 bg-gray-100 px-3 py-2 rounded-md">
              {benefit.icon}
              <span className="text-sm font-medium">{benefit.text}</span>
            </div>
          ))}
        </div>

        <div className="flex space-x-4 mt-8">
          <Link to="/get-a-quote" className="bg-blue-600 text-white px-6 py-3 rounded-lg text-lg hover:bg-blue-700">
            Get a Quote Now
          </Link>
        </div>
      </div>

      <div className="md:w-1/2 flex justify-center">
        <img src={InterpretationImage} alt="Interpretation illustration" className="w-full max-w-sm md:max-w-md" />
      </div>
    </section>
  );
};

export default InitialSection;
