import React from 'react';

const Consecutive: React.FC = () => {
  return (
    <div>
      <h1>Consecutive Interpretation</h1>
      <p>This service provides consecutive interpretation for meetings and events, allowing a pause for translation after each sentence.</p>
    </div>
  );
};

export default Consecutive;
