import React from 'react';
import '../styles/Testimonials.css';

const testimonials = [
    {
        name: "Ethan Miller",
        position: "Product Designer",
        image: "https://path/to/image1.jpg",
        rating: 5,
        text: "Using Trusted Interpreters has been a game-changer for our international projects. Their precision and professionalism have made cross-cultural communication seamless."
    },
    {
        name: "Emily Johnson",
        position: "Design Lead",
        image: "https://randomuser.me/api/portraits/women/10.jpg",
        rating: 5,
        text: "Thanks to Trusted Interpreters, I can confidently communicate with clients across languages, knowing every detail is accurately conveyed."
    },
    {
        name: "Olivia Carter",
        position: "Sales Manager",
        image: "https://path/to/image3.jpg",
        rating: 5,
        text: "The team at Trusted Interpreters has been instrumental in expanding our business reach. They consistently provide reliable and timely interpretations for our global meetings."
    },
    {
        name: "Wyatt Turner",
        position: "CEO",
        image: "https://randomuser.me/api/portraits/men/20.jpg",
        rating: 5,
        text: "Trusted Interpreters is a must-have for any business with international clients. Their service has greatly enhanced our client relationships worldwide."
    },
];

const TestimonialsSection: React.FC = () => {
    return (
        <section className="testimonials-section py-24">
            <div className="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mb-16 text-center">
                    <h2 className="text-4xl font-bold text-gray-900">This is what our Customers says about Us</h2>
                </div>

                <div className="testimonials-grid">
                    {testimonials.map((testimonial, index) => (
                        <div className="testimonial-card" key={index}>
                            <img className="testimonial-image" src={testimonial.image} alt={testimonial.name} />
                            <h5 className="text-gray-900 font-medium">{testimonial.name}</h5>
                            <p className="text-indigo-600">{testimonial.position}</p>
                            <div className="testimonial-rating">
                                {"★".repeat(testimonial.rating)}
                            </div>
                            <p className="testimonial-text">{testimonial.text}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default TestimonialsSection;
