import React from "react";
import { FiArrowRight } from "react-icons/fi";
import logo1 from "../assets/images/Clients logos_1_Accenture.webp";
import logo2 from "../assets/images/Clients logos_1_Airbus.webp";
import logo3 from "../assets/images/Clients logos_1_Banco Santander Central Hispano.webp";
import logo4 from "../assets/images/Clients logos_1_Bank Of America.webp";
import logo5 from "../assets/images/Clients logos_1_Citibank (Banamex USA).webp";
import logo6 from "../assets/images/Clients logos_1_Cornell University.webp";
import logo7 from "../assets/images/Clients logos_1_Dartmouth University.webp";
import logo8 from "../assets/images/Clients logos_1_Delta Airlines.webp";
import logo9 from "../assets/images/Clients logos_1_Duracell.webp";
import logo10 from "../assets/images/Clients logos_1_Federal Emergency Management Agency (FEMA).webp";
import logo11 from "../assets/images/Clients logos_1_FedEx.webp";
import logo12 from "../assets/images/Clients logos_1_Sony Electronics.webp";
import logo13 from "../assets/images/Clients logos_1_State Of Florida.webp";
import logo14 from "../assets/images/Clients logos_1_State Of New York.webp";
import logo15 from "../assets/images/Clients logos_1_Toyota.webp";
import logo16 from "../assets/images/Clients logos_1_United States Army.webp";

const logos = [
  logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8,
  logo9, logo10, logo11, logo12, logo13, logo14, logo15, logo16,
];

const ClientsCarousel: React.FC = () => {
  return (
    <div className="carousel-container flex items-center space-x-6 py-16 relative">
      <div className="flex items-center space-x-2 bg-white p-4 rounded-lg shadow-md w-auto">
        <p className="text-lg font-semibold text-gray-700 hover:text-blue-600 transition whitespace-nowrap">
          Clients that trust in Us
        </p>
        <FiArrowRight className="text-blue-600 hover:text-blue-800 transition" size={20} />
      </div>

      <div className="overflow-hidden w-full">
        <div className="carousel-track flex space-x-8 animate-marquee">
          {logos.concat(logos).map((logo, index) => (
            <div
              key={index}
              className="logo-item flex-shrink-0 hover:scale-125 transition-transform duration-300"
            >
              <img
                src={logo}
                alt={`Client logo ${index + 1}`}
                className="h-20 w-auto"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClientsCarousel;
