import React from "react";
import { Link } from "react-router-dom";
import { Languages, Users, Award } from "lucide-react";

const SignLanguageMenu: React.FC<{ onMouseLeave: () => void }> = ({ onMouseLeave }) => {
  return (
    <div
      className="absolute bg-white shadow-lg rounded-lg p-4 mt-2 w-64 z-50"
      onMouseLeave={onMouseLeave}
    >
      <ul>
        <li className="hover:bg-gray-100 rounded">
          <Link to="/interpretation-types/sign-language/english-sign-language" className="flex items-center p-2">
            <Languages className="h-6 w-6 text-blue-600 mr-3" />
            <div>
              <p className="text-gray-800 font-semibold">English Sign Language</p>
              <p className="text-gray-500 text-sm">Expert interpreters for English Sign Language.</p>
            </div>
          </Link>
        </li>
        <li className="hover:bg-gray-100 rounded">
          <Link to="/interpretation-types/sign-language/asl" className="flex items-center p-2">
            <Users className="h-6 w-6 text-blue-600 mr-3" />
            <div>
              <p className="text-gray-800 font-semibold">American Sign Language (ASL)</p>
              <p className="text-gray-500 text-sm">Certified ASL interpreters.</p>
            </div>
          </Link>
        </li>
        <li className="hover:bg-gray-100 rounded">
          <Link to="/interpretation-types/sign-language/rid-certified" className="flex items-center p-2">
            <Award className="h-6 w-6 text-blue-600 mr-3" />
            <div>
              <p className="text-gray-800 font-semibold">RID-Certified ASL</p>
              <p className="text-gray-500 text-sm">Top-quality RID-certified interpreters.</p>
            </div>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default SignLanguageMenu;
