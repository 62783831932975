import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import CitiesGrid from "../../../components/CitiesGrid";
import FAQ from "../../../components/FAQ";
import SubsectionNavigator from "../../../components/SubsectionNavigator";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import { FaUserShield, FaClock, FaHandshake } from "react-icons/fa";
import {
  FaClipboardCheck,
  FaCalendarAlt,
  FaUserClock,
  FaCommentDots,
} from "react-icons/fa";
import { FaMicroscope, FaHeadphones } from "react-icons/fa";
import OnsiteImage from "../../../../src/assets/images/adobe/onsite-img.webp";
import OnsiteImage1 from "../../../../src/assets/images/adobe/onsite-img-1.webp";
import OnsiteImage2 from "../../../../src/assets/images/adobe/onsite-img-2.webp";

const OnSite: React.FC = () => {
  const onSiteBenefits = [
    {
      icon: FaUserShield,
      title: "Confidentiality Guaranteed",
      text: "All interpreters are bound by confidentiality agreements and follow strict interpreting codes of ethics.",
    },
    {
      icon: FaClock,
      title: "International Coverage",
      text: "Over 10,000 language experts available throughout the U.S. and abroad, ready to serve any location, language, or time.",
    },
    {
      icon: FaHandshake,
      title: "Sector Specialization",
      text: "Expert linguists specialized in legal, business, education, medical, technical, military, and other industries.",
    },
  ];

  const subsections = [
    {
      title: "Consecutive",
      description: "Professional consecutive interpretation for formal events.",
      icon: <FaClipboardCheck size={24} />,
      link: "/interpretation-types/on-site/consecutive",
    },
    {
      title: "Simultaneous",
      description: "Real-time interpretation for live conferences.",
      icon: <FaMicroscope size={24} />,
      link: "/interpretation-types/on-site/simultaneous",
    },
    {
      title: "Equipment",
      description: "Rental equipment for interpretation needs.",
      icon: <FaHeadphones size={24} />,
      link: "/interpretation-types/on-site/equipment",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <PageHeader
        title="On-Site Interpretation Services Across the US"
        description="At Trusted Interpreters, we offer professional on-site interpretation services nationwide, connecting you with expert linguists for seamless communication in any setting. From legal proceedings and medical appointments to business meetings and conferences, our interpreters are ready to assist you anywhere in the United States."
        imageUrl={OnsiteImage}
      />

      <ContentSection
        title="Nationwide Network of Industry-Specific Interpreters"
        content="Trusted Interpreters connects you with a nationwide network of highly qualified professionals fluent in over 200 languages and dialects. 
                  We specialize in catering to diverse sectors, ensuring interpreters are well-versed in industry-specific terminology and cultural nuances. 
                  Our services include:"
        contentList={[
          "Legal",
          "Medical",
          "Business",
          "Education",
          "Technical Fields",
          "Military and Engineering",
        ]}
        additionalContent="With our customized solutions, we guarantee accurate and effective communication tailored to your unique needs."
        imageUrl={OnsiteImage1}
        direction="right"
      />

      {/* Subsections */}
      <div className="container mx-auto px-6 py-14">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
          Explore On-Site Interpretation Services
        </h2>
        <SubsectionNavigator subsections={subsections} />
      </div>

      {/* Workflow */}
      <HighlightedSection title="How Does It Work?">
        <ProcessWorkflow process="presencial" />
      </HighlightedSection>

      <ContentSection
        title="Flexible Scheduling with Unmatched Professionalism"
        content="At Trusted Interpreters, we adapt to your on-site interpretation needs with flexible scheduling and the ability to travel to any location across the U.S. Our interpreters uphold the highest standards of professionalism, adhering to strict codes of ethics and confidentiality agreements. This ensures reliable, secure, and tailored services, wherever and whenever you need them."
        imageUrl={OnsiteImage2}
        direction="left"
      />

      <CitiesGrid serviceName="On-Site" />

      {/* Key Benefits */}
      <HighlightedSection title="Why Choose Our On-Site Interpretation?">
        <KeyBenefits benefits={onSiteBenefits} />
      </HighlightedSection>

      {/* faq */}
      <div className="container mx-auto px-6 mb-10">
        <FAQ />
      </div>

      {/* testimonials */}
      <TestimonialsSection />

      <Footer />
    </div>
  );
};

export default OnSite;
