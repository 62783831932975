import React, { useState } from 'react';
import { FaComments, FaLightbulb, FaLanguage, FaEllipsis } from 'react-icons/fa6';
import { Link } from 'react-router-dom'; // Importa Link para navegación interna

const faqsData: { [key: string]: { question: string; answer: React.ReactNode }[] } = {
  Interpretations: [
    {
      question: "What is interpretation, and why is it important?",
      answer: (
        <>
          Interpretation involves converting spoken or signed language from one language to another in real-time. It ensures clear communication in settings like meetings, conferences, and medical appointments.
        </>
      ),
    },
    {
      question: "What’s the difference between simultaneous and consecutive interpretation?",
      answer: (
        <>
          <Link to="/interpretation-types/on-site/simultaneous" className="text-blue-600 underline hover:text-blue-800">
            <strong>Simultaneous Interpretation:</strong>
          </Link>{' '}
          Real-time translation as the speaker talks, ideal for conferences. <br />
          <Link to="/interpretation-types/on-site/consecutive" className="text-blue-600 underline hover:text-blue-800">
            <strong>Consecutive Interpretation:</strong>
          </Link>{' '}
          The interpreter speaks after the speaker pauses, best for smaller meetings.
        </>
      ),
    },
    {
      question: "What types of interpretation services do you offer?",
      answer: (
        <>
          We provide{' '}
          <Link to="/interpretation-types/on-site" className="text-blue-600 underline hover:text-blue-800">
            on-site
          </Link>,{' '}
          <Link to="/interpretation-types/over-the-phone" className="text-blue-600 underline hover:text-blue-800">
            over-the-phone (OPI)
          </Link>,{' '}
          <Link to="/interpretation-types/vri" className="text-blue-600 underline hover:text-blue-800">
            video remote (VRI)
          </Link>, 
          <Link to="/interpretation-types/on-site/simultaneous" className="text-blue-600 underline hover:text-blue-800">
            simultaneous
          </Link>,{' '} 
          <Link to="/interpretation-types/on-site/consecutive" className="text-blue-600 underline hover:text-blue-800">
            consecutive
          </Link>, and{' '}
          <Link to="/interpretation-types/sign-language" className="text-blue-600 underline hover:text-blue-800">
            sign language interpretation
          </Link>.
        </>
      ),
    },
  ],
  Expertise: [
    {
      question: "What industries do you serve?",
      answer: (
        <>
          Trusted Interpreters serves industries like{' '}
          <Link to="/expertise/legal-interpretation" className="text-blue-600 underline hover:text-blue-800">
            legal
          </Link>,{' '}
          <Link to="/expertise/medical-interpretation" className="text-blue-600 underline hover:text-blue-800">
            healthcare
          </Link>,{' '}
          <Link to="/expertise/immigration-interpretation" className="text-blue-600 underline hover:text-blue-800">
            immigration
          </Link>,{' '}
          <Link to="/expertise/business-interpretation" className="text-blue-600 underline hover:text-blue-800">
            business
          </Link>,{' '}
          <Link to="/expertise/educational-interpretation" className="text-blue-600 underline hover:text-blue-800">
            education
          </Link>, and{' '}
          <Link to="/expertise/government-interpretation" className="text-blue-600 underline hover:text-blue-800">
            government
          </Link>, and many more.
        </>
      ),      
    },
    {
      question: "Are your interpreters certified?",
      answer: (
        <>
          Yes, all{' '}
          <Link to="/about-us/our-interpreters" className="text-blue-600 underline hover:text-blue-800">
            our interpreters
          </Link>{' '}
          are professionally trained and certified to handle specific industry requirements.
        </>
      ),
    },
    {
      question: "Do you provide interpretation for immigration interviews?",
      answer: (
        <>
          Yes, we specialize in{' '}
          <Link to="/expertise/immigration-interpretation" className="text-blue-600 underline hover:text-blue-800">
            immigration-related services
          </Link>, including interviews, hearings, and naturalization ceremonies.
        </>
      ),
    },
  ],
  Languages: [
    {
      question: "What languages are available for interpretation?",
      answer: (
        <>
          We support over 200 languages, including{' '}
          <Link to="/language-expertise/spanish-expertise" className="text-blue-600 underline hover:text-blue-800">
            Spanish
          </Link>,{' '}
          <Link to="/language-expertise/french-expertise" className="text-blue-600 underline hover:text-blue-800">
            French
          </Link>,{' '}
          <Link to="/language-expertise/chinese-expertise" className="text-blue-600 underline hover:text-blue-800">
            Chinese
          </Link>,{' '}
          <Link to="/language-expertise/arabic-expertise" className="text-blue-600 underline hover:text-blue-800">
            Arabic
          </Link>,{' '}
          <Link to="/language-expertise/portuguese-expertise" className="text-blue-600 underline hover:text-blue-800">
            Portuguese
          </Link>, and{' '}
          <Link to="/interpretation-types/sign-language/asl" className="text-blue-600 underline hover:text-blue-800">
            American Sign Language (ASL)
          </Link>.
        </>
      ),
    },
    {
      question: "Can you handle rare languages or dialects?",
      answer: "Yes, our global network includes interpreters for less commonly spoken languages and regional dialects.",
    },
    {
      question: "Do you offer sign language interpretation?",
      answer: (
        <>
          Absolutely! We provide{' '}
          <Link to="/interpretation-types/sign-language/asl" className="text-blue-600 underline hover:text-blue-800">
            American Sign Language (ASL)
          </Link>,{' '}
          <Link to="/interpretation-types/sign-language/esl" className="text-blue-600 underline hover:text-blue-800">
            English Sign Language (ESL)
          </Link>, and{' '}
          <Link to="/interpretation-types/sign-language/rid-certified-interpreters" className="text-blue-600 underline hover:text-blue-800">
            RID-certified interpreters
          </Link>.
        </>
      ),
    },
  ],
  Others: [
    {
      question: "What payment methods do you accept?",
      answer: "We accept all major credit cards, PayPal, and other secure online payment methods.",
    },
    {
      question: "Is there a guarantee of confidentiality for my documents and conversations?",
      answer: "Yes, all interpreters adhere to strict confidentiality agreements, and we use secure systems to protect your data.",
    },
    {
      question: "Can I book an interpreter at the last minute?",
      answer: "Yes, we specialize in urgent and last-minute requests, ensuring access to interpreters 24/7.",
    },
  ],
};

const FAQ: React.FC = () => {
  const [selectedSection, setSelectedSection] = useState<keyof typeof faqsData>("Interpretations");
  const [activeQuestion, setActiveQuestion] = useState<number>(0);

  const sections = [
    { name: "Interpretations", icon: <FaComments /> },
    { name: "Expertise", icon: <FaLightbulb /> },
    { name: "Languages", icon: <FaLanguage /> },
    { name: "Others", icon: <FaEllipsis /> },
  ];

  const selectSection = (sectionName: keyof typeof faqsData) => {
    setSelectedSection(sectionName);
    setActiveQuestion(0);
  };

  const toggleQuestion = (index: number) => {
    setActiveQuestion(activeQuestion === index ? -1 : index);
  };

  return (
    <div className="container mx-auto mt-16 px-8 py-16 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-gray-800 -mt-10 mb-2">Frequently Asked Questions</h2>
      <div className="flex mt-10">
        <div className="w-1/4">
          <nav className="space-y-4">
            {sections.map((section) => (
              <button
                key={section.name}
                onClick={() => selectSection(section.name as keyof typeof faqsData)}
                className={`flex items-center space-x-3 w-full px-4 py-2 rounded-lg ${
                  selectedSection === section.name ? 'bg-blue-100 text-blue-600 font-semibold' : 'text-gray-600'
                } hover:bg-blue-50`}
              >
                <span className="text-xl">{section.icon}</span>
                <span>{section.name}</span>
              </button>
            ))}
          </nav>
        </div>

        <div className="w-3/4 pl-8">
          <h3 className="text-2xl font-bold text-blue-600 mb-4">{selectedSection}</h3>
          <div className="space-y-4">
            {faqsData[selectedSection].map((faq, index) => (
              <div key={index}>
                <button
                  className="text-lg font-semibold text-left w-full text-gray-800 hover:text-blue-600 focus:outline-none"
                  onClick={() => toggleQuestion(index)}
                >
                  {faq.question}
                  <span className="float-right">{activeQuestion === index ? "−" : "+"}</span>
                </button>
                <div
                  className={`overflow-hidden transition-max-height duration-300 ease-in-out ${
                    activeQuestion === index ? 'max-h-screen' : 'max-h-0'
                  }`}
                >
                  {activeQuestion === index && (
                    <div className="text-gray-600 mt-2 transition-opacity duration-300 ease-in-out">
                      {faq.answer}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
