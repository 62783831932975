import React, { useState, useRef } from "react";
import "./App.css";
import { ChevronDown, ChevronUp } from "lucide-react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
// menu header
import OnSiteMenu from "./components/OnSiteMenu";
import OverThePhoneMenu from "./components/OverThePhoneMenu";
import VRIMenu from "./components/VRIMenu";
import SignLanguageMenu from "./components/SignLanguageMenu";
import AboutUsMenu from "./components/AboutUsMenu";
import Home from "./pages/home";

// images
import LogoImage from "./assets/images/logo_ti.jpg";

// on-site pages
import OnSite from "./pages/interpretation-types/on-site/on-site";
import Consecutive from "./pages/interpretation-types/on-site/consecutive";
import Simultaneous from "./pages/interpretation-types/on-site/simultaneous";
import Equipment from "./pages/interpretation-types/on-site/equipment";

// over the phone pages
import OverThePhone from "./pages/interpretation-types/over-the-phone";

// vri pages
import VRI from "./pages/interpretation-types/vri";

// sign language pages
import SignLanguage from "./pages/interpretation-types/sign-language";
import ASL from "./pages/interpretation-types/asl-interpretation/American-Sign-Language";
import ESL from "./pages/interpretation-types/asl-interpretation/English-Sign-Language";
import RID from "./pages/interpretation-types/asl-interpretation/RID-Certified-ASL-Interpreters";
import ConsecutiveSimultaneous from "./pages/interpretation-types/consecutive-simultaneous";

// about us
import AboutUs from "./pages/about-us";
import Blog from "./pages/blog/Blog";
import PostPage from "./pages/blog/PostPage";
import Interpreters from "./pages/about-us/our-interpreters/Interpreters";

// languages pages
import SpanishExpertise from "./pages/language-expertise/Spanish-Interpretation";

// industries pages
import LegalInterpretation from "./pages/expertise/legal-interpretation/Legal-Interpretation";

// cities
import Austin from "./pages/interpretation-services/austin";
import Boston from "./pages/interpretation-services/boston";
import Charlotte from "./pages/interpretation-services/charlotte";
import Chicago from "./pages/interpretation-services/chicago";
import Columbus from "./pages/interpretation-services/columbus";
import Dallas from "./pages/interpretation-services/dallas";
import Denver from "./pages/interpretation-services/denver";
import ElPaso from "./pages/interpretation-services/el-paso";
import FortWorth from "./pages/interpretation-services/fort-worth";
import Houston from "./pages/interpretation-services/houston";
import Indianapolis from "./pages/interpretation-services/indianapolis";
import Jacksonville from "./pages/interpretation-services/jacksonville";
import LosAngeles from "./pages/interpretation-services/los-angeles";
import Nashville from "./pages/interpretation-services/nashville";
import NewYork from "./pages/interpretation-services/new-york";
import OklahomaCity from "./pages/interpretation-services/oklahoma-city";
import Philadelphia from "./pages/interpretation-services/philadelphia";
import Phoenix from "./pages/interpretation-services/phoenix";
import Portland from "./pages/interpretation-services/portland";
import SanAntonio from "./pages/interpretation-services/san-antonio";
import SanDiego from "./pages/interpretation-services/san-diego";
import SanFrancisco from "./pages/interpretation-services/san-francisco";
import SanJose from "./pages/interpretation-services/san-jose";
import Seattle from "./pages/interpretation-services/seattle";
import Washington from "./pages/interpretation-services/washington";

const App: React.FC = () => {
  const [activeMenu, setActiveMenu] = useState<string | null>(null);
  const closeTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleMouseEnter = (menu: string) => {
    if (closeTimeout.current) {
      clearTimeout(closeTimeout.current);
    }
    setActiveMenu(menu);
  };

  const handleMouseLeave = () => {
    closeTimeout.current = setTimeout(() => {
      setActiveMenu(null);
    }, 200);
  };

  return (
    <Router>
      <div className="min-h-screen flex flex-col bg-gray-50">
        <header className="bg-white shadow-sm sticky top-0 z-50">
          <nav className="container mx-auto px-4 py-3 flex justify-between items-center">
            {/* Logo */}
            <Link to="/">
              <img
                src={LogoImage}
                alt="Trusted Interpreters Logo"
                className="h-16 w-auto"
              />
            </Link>

            {/* Navigation */}
            <ul className="hidden md:flex space-x-8">
              {/* On-Site */}
              <li
                onMouseEnter={() => handleMouseEnter("OnSite")}
                onMouseLeave={handleMouseLeave}
                className="relative"
              >
                <Link
                  to="/interpretation-types/on-site"
                  className="text-gray-600 hover:text-blue-600 flex items-center space-x-1"
                >
                  <span>On-Site</span>
                  {activeMenu === "OnSite" ? (
                    <ChevronUp className="h-4 w-4 text-gray-600" />
                  ) : (
                    <ChevronDown className="h-4 w-4 text-gray-600" />
                  )}
                </Link>
                {activeMenu === "OnSite" && (
                  <OnSiteMenu onMouseLeave={handleMouseLeave} />
                )}
              </li>

              {/* Over-The-Phone */}
              <li
                onMouseEnter={() => handleMouseEnter("OverThePhone")}
                onMouseLeave={handleMouseLeave}
                className="relative"
              >
                <Link
                  to="/interpretation-types/over-the-phone"
                  className="text-gray-600 hover:text-blue-600 flex items-center space-x-1"
                >
                  <span>Over-The-Phone</span>
                  {activeMenu === "OverThePhone" ? (
                    <ChevronUp className="h-4 w-4 text-gray-600" />
                  ) : (
                    <ChevronDown className="h-4 w-4 text-gray-600" />
                  )}
                </Link>
                {activeMenu === "OverThePhone" && (
                  <OverThePhoneMenu onMouseLeave={handleMouseLeave} />
                )}
              </li>

              {/* VRI */}
              <li
                onMouseEnter={() => handleMouseEnter("VRI")}
                onMouseLeave={handleMouseLeave}
                className="relative"
              >
                <Link
                  to="/interpretation-types/vri"
                  className="text-gray-600 hover:text-blue-600 flex items-center space-x-1"
                >
                  <span>VRI</span>
                  {activeMenu === "VRI" ? (
                    <ChevronUp className="h-4 w-4 text-gray-600" />
                  ) : (
                    <ChevronDown className="h-4 w-4 text-gray-600" />
                  )}
                </Link>
                {activeMenu === "VRI" && (
                  <VRIMenu onMouseLeave={handleMouseLeave} />
                )}
              </li>

              {/* Sign Language */}
              <li
                onMouseEnter={() => handleMouseEnter("SignLanguage")}
                onMouseLeave={handleMouseLeave}
                className="relative"
              >
                <Link
                  to="/interpretation-types/sign-language"
                  className="text-gray-600 hover:text-blue-600 flex items-center space-x-1"
                >
                  <span>Sign Language</span>
                  {activeMenu === "SignLanguage" ? (
                    <ChevronUp className="h-4 w-4 text-gray-600" />
                  ) : (
                    <ChevronDown className="h-4 w-4 text-gray-600" />
                  )}
                </Link>
                {activeMenu === "SignLanguage" && (
                  <SignLanguageMenu onMouseLeave={handleMouseLeave} />
                )}
              </li>

              {/* About Us */}
              <li
                onMouseEnter={() => handleMouseEnter("AboutUs")}
                onMouseLeave={handleMouseLeave}
                className="relative"
              >
                <Link
                  to="/about-us"
                  className="text-gray-600 hover:text-blue-600 flex items-center space-x-1"
                >
                  <span>About Us</span>
                  {activeMenu === "AboutUs" ? (
                    <ChevronUp className="h-4 w-4 text-gray-600" />
                  ) : (
                    <ChevronDown className="h-4 w-4 text-gray-600" />
                  )}
                </Link>
                {activeMenu === "AboutUs" && (
                  <AboutUsMenu onMouseLeave={handleMouseLeave} />
                )}
              </li>

              {/* Blog */}
              <li>
                <Link to="/blog" className="text-gray-600 hover:text-blue-600">
                  Blog
                </Link>
              </li>
            </ul>

            {/* Get a Quote Button */}
            <Link
              to="/get-a-quote"
              className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
            >
              Get a Quote Now
            </Link>
          </nav>
        </header>

        {/* Routes */}
        <Routes>
          <Route path="/" element={<Home />} />
          {/* interpretation types */}
          {/* on-site route*/}
          <Route path="/interpretation-types/on-site" element={<OnSite />} />
          <Route path="/interpretation-types/on-site/consecutive" element={<Consecutive />} />
          <Route path="/interpretation-types/on-site/simultaneous" element={<Simultaneous />} />
          <Route path="/interpretation-types/on-site/equipment" element={<Equipment />} />
          
          {/* over the phone route */}
          <Route path="/interpretation-types/over-the-phone" element={<OverThePhone />} />

          {/* vri route */}
          <Route path="/interpretation-types/vri" element={<VRI />} />

          {/* sign language route */}
          <Route path="/interpretation-types/sign-language" element={<SignLanguage />} />
          <Route path="/interpretation-types/sign-language/asl" element={<ASL />} />
          <Route path="/interpretation-types/sign-language/esl" element={<ESL />} />
          <Route path="/interpretation-types/sign-language/RID-certified-interpreters" element={<RID />} />

          <Route path="/interpretation-types/consecutive-simultaneous" element={<ConsecutiveSimultaneous />} />

          {/* about us & blog */}
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/about-us/our-interpreters" element={<Interpreters />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<PostPage />} />
          
          {/* languages route */}
          <Route path="/language-expertise/spanish-expertise" element={<SpanishExpertise />} />

          {/* expertise route */}
          <Route path="/expertise/legal-interpretation" element={<LegalInterpretation />} />

          {/* cities route */}
          <Route path="/interpretation-services/austin" element={<Austin />} />
          <Route path="/interpretation-services/boston" element={<Boston />} />
          <Route path="/interpretation-services/charlotte" element={<Charlotte />} />
          <Route path="/interpretation-services/chicago" element={<Chicago />} />
          <Route path="/interpretation-services/columbus" element={<Columbus />} />
          <Route path="/interpretation-services/dallas" element={<Dallas />} />
          <Route path="/interpretation-services/denver" element={<Denver />} />
          <Route path="/interpretation-services/el-paso" element={<ElPaso />} />
          <Route path="/interpretation-services/fort-worth" element={<FortWorth />} />
          <Route path="/interpretation-services/indianapolis" element={<Indianapolis />} />
          <Route path="/interpretation-services/jacksonville" element={<Jacksonville />} />
          <Route path="/interpretation-services/los-angeles" element={<LosAngeles />} />
          <Route path="/interpretation-services/nashville" element={<Nashville />} />
          <Route path="/interpretation-services/new-york" element={<NewYork />} />
          <Route path="/interpretation-services/oklahoma-city" element={<OklahomaCity />} />
          <Route path="/interpretation-services/philadelphia" element={<Philadelphia />} />
          <Route path="/interpretation-services/phoenix" element={<Phoenix />} />
          <Route path="/interpretation-services/portland" element={<Portland />} />
          <Route path="/interpretation-services/san-antonio" element={<SanAntonio />} />
          <Route path="/interpretation-services/san-diego" element={<SanDiego />} />
          <Route path="/interpretation-services/san-francisco" element={<SanFrancisco />} />
          <Route path="/interpretation-services/san-jose" element={<SanJose />} />
          <Route path="/interpretation-services/seattle" element={<Seattle />} />
          <Route path="/interpretation-services/washington" element={<Washington />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
