import React from "react";
import { FaLinkedin, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-900 text-white">
      <div className="container mx-auto px-4 py-12 grid grid-cols-1 md:grid-cols-3 gap-8">
        <div>
          <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
          <ul className="space-y-6">
            <li>
              Email:{" "}
              <a
                href="mailto:info@trustedinterpreters.com"
                className="text-blue-400 hover:underline"
              >
                info@trustedinterpreters.com
              </a>
            </li>
            <li>
              Phone:{" "}
              <a
                href="tel:+123456789"
                className="text-blue-400 hover:underline"
              >
                +1 (234) 567-89
              </a>
            </li>
            <li>
              Social Media:
              <div className="flex space-x-4 mt-2">
                <a
                  href="#"
                  aria-label="Twitter"
                  className="text-blue-400 hover:text-blue-600"
                >
                  <FaXTwitter size={20} />
                </a>
                <a
                  href="#"
                  aria-label="LinkedIn"
                  className="text-blue-400 hover:text-blue-600"
                >
                  <FaLinkedin size={20} />
                </a>
                <a
                  href="#"
                  aria-label="Instagram"
                  className="text-blue-400 hover:text-blue-600"
                >
                  <FaInstagram size={20} />
                </a>
              </div>
            </li>
          </ul>
        </div>

        <div>
          <h3 className="text-lg font-semibold mb-4">Other Services</h3>
          <ul className="space-y-4">
            <li>
              <a href="#" className="hover:underline">
                Translation Services
              </a>
            </li>
            <li>
              <a href="#" className="hover:underline">
                Subtitling Services
              </a>
            </li>
            <li>
              <a href="#" className="hover:underline">
                Localization Services
              </a>
            </li>
            <li>
              <a href="#" className="hover:underline">
                Voice Over Services
              </a>
            </li>
          </ul>
        </div>

        <div>
          <h3 className="text-lg font-semibold mb-4">
            Cities for Interpretation Services
          </h3>
          <ul className="space-y-4">
            <li>
              <a href="#" className="hover:underline">
                Interpretation Services in Miami
              </a>
            </li>
            <li>
              <a href="#" className="hover:underline">
                Interpretation Services in New York
              </a>
            </li>
            <li>
              <a href="#" className="hover:underline">
                Interpretation Services in San Francisco
              </a>
            </li>
            <li>
              <a href="#" className="hover:underline">
                Interpretation Services in Philadelphia
              </a>
            </li>
            <li>
              <a href="#" className="hover:underline">
                More cities
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* sub-footer */}
      <div className="bg-gray-800 py-4 text-center text-sm text-gray-400">
        <span>
          &copy; 2024 Trusted Interpreters. All rights reserved.{" "}
          <a href="#" className="hover:underline">
            Sitemap
          </a>{" "}
          |{" "}
          <a href="#" className="hover:underline">
            Terms and Conditions
          </a>{" "}
          |{" "}
          <a href="#" className="hover:underline">
            Privacy Policy
          </a>
        </span>
      </div>
    </footer>
  );
};

export default Footer;