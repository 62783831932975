import React from "react";

interface PageHeaderProps {
  title: string;
  description: string;
  imageUrl: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, description, imageUrl }) => {
  return (
    <div
      className="relative bg-gradient-to-r from-blue-500 to-blue-700 text-white py-12"
      style={{ background: "linear-gradient(to right, #024772, #e5e9ec)" }}
    >
      <div className="container mx-auto px-6 flex flex-col md:flex-row items-center">
        {/* Texto */}
        <div className="md:w-1/2 p-6 animate-fade-in">
          <h1 className="text-3xl md:text-4xl font-bold leading-snug mb-4">
            {title}
          </h1>
          <p className="text-lg md:text-lg leading-relaxed mb-6">
            {description}
          </p>
          <div>
            <button className="bg-white text-blue-600 px-6 py-2 rounded-lg font-semibold hover:bg-green-600 hover:text-white transition">
              Get a Quote
            </button>
          </div>
        </div>

        {/* Imagen */}
        <div className="md:w-1/2 p-6 flex justify-center">
          <img
            src={imageUrl}
            alt={title}
            className="max-w-full md:max-w-lg rounded-lg shadow-lg object-cover border border-gray-200 animate-slide-in ml-10"
            />
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
