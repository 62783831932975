
import React from "react";

const Charlotte: React.FC = () => {
  return (
    <div>
      <h1>Interpretation Services in charlotte</h1>
      <p>
        Welcome to our page for interpretation services in charlotte. 
        We provide top-notch interpretation services for various needs.
      </p>
    </div>
  );
};

export default Charlotte;
