import React from 'react';

interface Benefit {
  icon: React.ElementType;
  title: string;
  text: string;
}

interface KeyBenefitsProps {
  benefits: Benefit[];
  className?: string;
}

const KeyBenefits: React.FC<KeyBenefitsProps> = ({ benefits, className }) => {
  const topRowBenefits = benefits.slice(0, 3);
  const bottomRowBenefits = benefits.slice(3);

  return (
    <div className={className}>
      {/* Top row - 3 cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
        {topRowBenefits.map((benefit, index) => (
          <div
            key={index}
            className="flex flex-col items-center text-center bg-white shadow-md rounded-lg p-6"
          >
            <div className="text-blue-600 bg-blue-100 p-4 rounded-full mb-4">
              <benefit.icon className="w-8 h-8" />
            </div>
            <h3 className="text-lg font-semibold text-gray-800 mb-2">
              {benefit.title}
            </h3>
            <p className="text-gray-600">{benefit.text}</p>
          </div>
        ))}
      </div>

      {/* Bottom row - 2 centered cards */}
      <div className="flex flex-col md:flex-row justify-center gap-8">
        {bottomRowBenefits.map((benefit, index) => (
          <div
            key={index}
            className="flex flex-col items-center text-center bg-white shadow-md rounded-lg p-6 w-full md:w-[30%]"
          >
            <div className="text-blue-600 bg-blue-100 p-4 rounded-full mb-4">
              <benefit.icon className="w-8 h-8" />
            </div>
            <h3 className="text-lg font-semibold text-gray-800 mb-2">
              {benefit.title}
            </h3>
            <p className="text-gray-600">{benefit.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KeyBenefits;

