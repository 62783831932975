import React from 'react';

const OverThePhone: React.FC = () => {
  return (
    <div>
      <h1>Over-The-Phone Interpretation</h1>
    </div>
  );
};

export default OverThePhone;
