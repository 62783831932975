
import React from "react";

const Indianapolis: React.FC = () => {
  return (
    <div>
      <h1>Interpretation Services in indianapolis</h1>
      <p>
        Welcome to our page for interpretation services in indianapolis. 
        We provide top-notch interpretation services for various needs.
      </p>
    </div>
  );
};

export default Indianapolis;
