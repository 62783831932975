import React from "react";
import { Link } from "react-router-dom";
import { Users, Award } from "lucide-react";

const AboutUsMenu: React.FC<{ onMouseLeave: () => void }> = ({ onMouseLeave }) => {
  return (
    <div
      className="absolute bg-white shadow-lg rounded-lg p-4 mt-2 w-64 z-50"
      onMouseLeave={onMouseLeave}
    >
      <ul>
        <li className="hover:bg-gray-100 rounded">
          <Link to="/about-us/our-interpreters" className="flex items-center p-2">
            <Users className="h-6 w-6 text-blue-600 mr-3" />
            <div>
              <p className="text-gray-800 font-semibold">Our Interpreters</p>
              <p className="text-gray-500 text-sm">Meet our certified interpreters.</p>
            </div>
          </Link>
        </li>
        <li className="hover:bg-gray-100 rounded">
          <Link to="/about-us/certifications" className="flex items-center p-2">
            <Award className="h-6 w-6 text-blue-600 mr-3" />
            <div>
              <p className="text-gray-800 font-semibold">Certifications</p>
              <p className="text-gray-500 text-sm">Learn about our qualifications and certifications.</p>
            </div>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default AboutUsMenu;
