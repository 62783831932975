import React from 'react';

const VRI: React.FC = () => {
  return (
    <div>
      <h1>VRI</h1>
    </div>
  );
};

export default VRI;